import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_SETTINGS,
    SET_SETTINGS,
    REQUEST_CHANGE_AUTO_START,
    REQUEST_CHANGE_AUTO_RESULT,
    SET_CHANGE_AUTO_RESULT,
    SET_CHANGE_AUTO_START
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import initialStore from "../initialStore";


function* fetchGameStart() {
    const settings =  yield call(GetResource, `/dealerSettings` );
    yield put({type: SET_SETTINGS, settings});
}

function* changeSettings({data}) {
    return yield call(PutResource, `/dealerSettings`, data );
}

function* changeAutoStart(data) {
    const settings = yield call(changeSettings, data);
    console.log(settings)
    yield put({type: SET_CHANGE_AUTO_START, autoStart: settings.autoStart});
    
}

function* changeAutoResult(data) {
    const settings = yield call(changeSettings, data);
    console.log(settings)
    yield put({type: SET_CHANGE_AUTO_RESULT, autoResult: settings.autoResult});
}

function* settingsSaga() {
    yield takeLatest(REQUEST_SETTINGS, safe(onError, fetchGameStart));
    yield takeLatest(REQUEST_CHANGE_AUTO_START, safe(onError, changeAutoStart));
    yield takeLatest(REQUEST_CHANGE_AUTO_RESULT, safe(onError, changeAutoResult));
}

export default settingsSaga;