import initialStore from "../initialStore";
import { SET_ROUND_ID } from "@actions/actionTypes";

export default function roundIdReducer(state = "", action) {
    switch (action.type) {
        case SET_ROUND_ID:
            return  action.roundId;
        default:
            return state;
    }
}
