import React, {useEffect, useState} from "react";
import CardListItem from "@components/CardsList/CardListItem";
import classNames from "classnames";
import {playerTypes} from "@constants/Enums";

function CardsList({cards, className, isActive}) {

    return(
        <ul className={classNames("cards-list", className)}>
            {
                cards.map((card, index)=> (<CardListItem
                    card={card} key={`${index}`} cardList={cards}
                    // disabled={!isActive}
                />))
            }
        </ul>
    )
}

export default CardsList