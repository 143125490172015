import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Scanner from "@components/Scanner/Scanner";
import {subscribeTickerWorker, unSubscribeTickerWorker} from "@actions/workerActions";

function Timer({
                   subscribeTickerWorker,
                   unSubscribeTickerWorker,
                   cards,
                   winner,
                   suspended
               }) {

    const [betDuration, setBetDuration] = useState()
    const [ticker, setTicker] = useState(undefined);

    useEffect(() => {
        function getServerTime(e) {
            const time = JSON.parse(e.data);
            setBetDuration(time.data)
        }

        subscribeTickerWorker(getServerTime);
        if (suspended) {
            unSubscribeTickerWorker(getServerTime)
            setTicker(0)
        }
        return () => {
            unSubscribeTickerWorker(getServerTime)
        }
    }, [suspended])



    useEffect(() => {
        if (cards.length < 6 && winner) {
            setTicker(0)
        } else {
            setTicker(betDuration === undefined ? undefined : parseInt(betDuration / 1000))
        }

    }, [betDuration])

    return (<div className="ticker">
        <div className="tickerArea">{ticker > 0 && ticker}</div>
        <Scanner ticker={ticker}/>
    </div>)
}

function mapStateToProps(state) {
    return {
        cards: state.cards,
        winner: state.winner,
        suspended: state.suspended
    }
}

const mapDispatchToProps = {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)