import {
    SET_CARDS
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function cardsReducer(state = initialStore.cards, action) {
    switch (action.type) {
        case SET_CARDS:
            if (action.cards.bankerCards.length == 0 && action.cards.playerCards.length == 0) {
                return initialStore.cards;
            }
            return {
                bankerCards: state.bankerCards.map((item, index) => action.cards.bankerCards[index] != undefined? action.cards.bankerCards[index] : item),
                bankerCardWeight: action.cards.bankerCardWeight,
                playerCards: state.playerCards.map((item, index) => action.cards.playerCards[index] != undefined? action.cards.playerCards[index] : item),
                playerCardWeight: action.cards.playerCardWeight,
            }
        default:
            return state;
    }
}