import initialStore from "../initialStore";
import { SET_TURN_TYPE } from "@actions/actionTypes";

export default function turnReducer(state = initialStore.turn, action) {
    switch (action.type) {
        case SET_TURN_TYPE:
            return  action.turn;
        default:
            return state;
    }
}
