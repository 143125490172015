import React, {useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import { CardTypeMap, CardSuit } from "@constants/Enums";

function CardListItem({card, cardList, suspended, key, disabled}) {
    return (
        <li className={classNames("cards-list-item", {disabled})} key={key}>
            <div className="card">
                <div className={classNames("flip-card", {active: card.type})} >
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src={cardBg} alt={cardBg} />
                        </div>
                        <div className="flip-card-back">
                            <img src={openedCardBg} alt={openedCardBg} />
                            <i className={`cardIcon icon-${card.type && CardTypeMap[card.type]}${card.suit && CardSuit[card.suit]}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}
const mapStateToProps = (state) => {
    return {
        suspended: state.suspended
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CardListItem)