import React, {useLayoutEffect, useState} from "react";
import {connect} from "react-redux";
import {Button} from "antd";
import {FormattedMessage} from "react-intl";
import CustomSelectModal from "@components/CustomSelectModal/CustomSelectModal";
import cardsEnum from "@constants/cards";
import {scanCard} from "@actions/cardAction";
import {getTimeFromTickerWorker} from "@actions/workerActions";
import {setErrorMsg} from "@actions/alertAction";

function CustomSelect({scanCard, getTimeFromTickerWorker, setErrorMsg, cards, disabledCustomSelect, autoFocus}) {
    const [showSelectModal, setShowSelectModal] = useState(false)

    const selectCard = (value, deck) => {
        let code = ""
        try {
            cardsEnum.forEach(item => {
                let cardName = item.CardName.slice(0, -1)
                let suit = item.CName.split(" ")[2].toLowerCase()
                if (value === cardName + suit && item.Deck == deck) {
                    code = item.Code
                    throw 'Break'
                }
            })
        } catch (e) {
            if (e !== 'Break') throw e
        }

        let existingPlayerCardIndex = cards.playerCards.findIndex(card => card.code == code)
        let existingBankerCardIndex = cards.bankerCards.findIndex(card => card.code == code)

        if (existingPlayerCardIndex === -1 && existingBankerCardIndex === -1) {
            setShowSelectModal(false)
            scanCard(code)
            getTimeFromTickerWorker()
        } else {
            setErrorMsg("card_already_opened")
        }
    }

    useLayoutEffect(() => {
        autoFocus()
    }, [showSelectModal])

    return(
        <div>
            {
                <Button className="miniBtn"
                                           disabled={disabledCustomSelect}
                                           onClick={() => setShowSelectModal(true)}>
                    <FormattedMessage id='select'/>
                </Button>
            }
            <CustomSelectModal visible={showSelectModal}
                               onCancel={() => setShowSelectModal(false)}
                               onConfirm={selectCard}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cards: state.cards,
    }
}

const mapDispatchToProps = {
    scanCard,
    getTimeFromTickerWorker,
    setErrorMsg
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSelect)